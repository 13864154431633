<template>
  <div
    class="register"
    :style="{
      backgroundImage: 'url(' + bg + ')',
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
    }"
  >
    <el-row>
      <el-col
        class="registerBox"
        :xs="{ offset: '2', span: '20' }"
        :sm="{ offset: '4', span: '16' }"
        :md="{ offset: '12', span: '8' }"
        :lg="{ offset: '12', span: '8' }"
        :xl="{ offset: '12', span: '6' }"
      >
        <div class="title">注册</div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="46px"
          class="demo-ruleForm"
        >
          <el-form-item label prop="phone">
            <el-input
              type="phone"
              v-model="ruleForm.phone"
              @focus="inputIcon = ''"
              @blur="checkName"
              @input="change($event)"
              placeholder="请输入11位手机号"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="inputIcon == 'el-icon-circle-close'"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="inputIcon == 'el-icon-circle-check'"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item label prop="code">
            <el-row>
              <el-col :span="15">
                <el-input
                  v-model="ruleForm.code"
                  @input="change($event)"
                  placeholder="输入验证码"
                >
                  <i
                    slot="suffix"
                    style="color: red"
                    v-if="ruleForm.code.length != 6 && ruleForm.code.length > 0"
                    class="el-icon-circle-close"
                  ></i>
                  <i
                    slot="suffix"
                    style="color: green"
                    v-if="ruleForm.code.length == 6"
                    class="el-icon-circle-check"
                  ></i>
                </el-input>
              </el-col>
              <el-col :span="8" :offset="1" class>
                <el-button
                  :disabled="disableds"
                  @click.native="getMsg"
                  style="padding: 12px 6px; width: 100%"
                  >{{
                    disableds ? secendNo + "S后重新获取" : "发送验证码"
                  }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              @focus="checkPwd = null"
              @blur="checkPassword"
              @input="change($event)"
              placeholder="请输入密码"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="checkPwd"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="checkPwd === false"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>
          <p class="pwdtips" v-show="checkPwd">
            密码应为8-16个字符，至少包含数字和字母
          </p>
          <el-form-item label prop="inPhone">
            <el-input
              v-model="ruleForm.inPhone"
              @input="change($event)"
              placeholder="请输入推荐号或推荐人手机号(选填)"
              @blur="checktuijian"
            >
              <i
                slot="suffix"
                style="color: red"
                v-if="checkTuijianed"
                class="el-icon-circle-close"
              ></i>
              <i
                slot="suffix"
                style="color: green"
                v-if="checkTuijianed === false"
                class="el-icon-circle-check"
              ></i>
            </el-input>
          </el-form-item>
          <el-form-item label prop="desc" style="text-align: left">
            <el-checkbox v-model="ruleForm.isOk" @input="change($event)">
              我已阅读并同意
              <span @click.stop="toService">《服务协议》</span>
            </el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 100%; background: blue"
              @click.native="register"
              >注册</el-button
            >
          </el-form-item>
          <el-form-item class="noMargrin">
            <el-row>
              <el-col class="iconBox" :span="16">
                <span>其他注册方式</span>
                <img
                  :src="require('@/assets/images/icons/qqlink.png')"
                  alt
                  @click="qingqiuqqlogin"
                />
                <img :src="require('@/assets/images/icons/wxlink.png')" alt />
                <img
                  :src="require('@/assets/images/icons/weibolink.png')"
                  alt
                  @click="qingqiuwblogin"
                />
              </el-col>
              <el-col :span="8">
                <span @click="tologin" class="pointer">已有账户直接登录</span>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item class="noMargrin">
            <el-row>
              <el-col
                :span="6"
                :offset="6"
                class="pointer"
                @click.native="gotoNext('joiningQuestion')"
                >新手帮助</el-col
              >
              <el-col
                :span="6"
                class="pointer"
                @click.native="gotoNext('joiningService')"
                >隐私协议</el-col
              >
            </el-row>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from "axios";
import { aoiGetUserinfo } from "@/api/login";

export default {
  name: "register",
  data() {
    return {
      bg: require("@/assets/images/logbgs.png"),
      inputIcon: "",
      ruleForm: {
        phone: "",
        password: "",
        inPhone: "",
        code: "",
        isOk: false,
      },
      checkPwd: null,
      checkTuijianed: null,
      token: "",
      disableds: false,
      secendNo: 60,
      timer: null,
      rules: {
        phone: [
          {
            required: true,
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
      },
      name: "",
      password: "",
      inPhone: "",
      code: "",
      isOk: false,
    };
  },

  mounted() {
    this.ruleForm.inPhone = location.href.split("?")[1];
    // this.checktuijian();
  },
  methods: {
    gotoNext(val) {
      this.$router.push({ name: val });
      this.$store.commit("SET_INFO", ["menuIndex", "joining"]);
    },
    //校验手机号
    checkName() {
      var myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(this.ruleForm.phone)) {
        this.inputIcon = "el-icon-circle-close";
        return false;
      } else {
        this.inputIcon = "el-icon-circle-check";
        return true;
      }
    },
    qingqiuqqlogin() {
      const data = {};
      axios.post("/wealth/qqRequestLogin", data).then(() => {
        location.href =
          "https://graph.qq.com/oauth2.0/show?which=Login&display=pc&response_type=code&client_id=101846023&redirect_uri=https%3a%2f%2fwww.lcpz.com%2fqq&state=test";
      });
    },
    qingqiuwblogin() {
      const data = {};
      axios.post("/wealth/wbRequestLogin", data).then(() => {
        location.href =
          "https://api.weibo.com/oauth2/authorize?client_id=98634165&response_type=code&redirect_uri=https%3a%2f%2flcpz.com%2fwb";
      });
    },
    change() {
      this.$forceUpdate();
    },
    checktuijian() {
      // const that = this;
      // const data = {
      //   name: this.ruleForm.inPhone,
      // };
      // axios.post("/wealth/accountIsCorrect", data)
      //   .then((res) => {
      //     if (res.data) {
      //       that.checkTuijianed = false;
      //     } else {
      //       that.checkTuijianed = true;
      //     }
      //   });
    },
    //校验密码
    checkPassword() {
      const str = /^[a-z0-9_-]{8,16}$/;
      this.checkPwd = !str.test(this.ruleForm.password);
    },
    /*
     *@date: 2019-11-06 20:17:52
     *@description: 获取验证码
     */
    //隐私协议
    toService() {
      this.$router.push({ name: "service" });
      this.$store.commit("setInfo", ["menuIndex", 3]);
      this.$store.commit("setInfo", ["aboutIndex", "service"]);
    },
    //新手帮助
    toNewHelp() {
      this.$router.push({ name: "question" });
      this.$store.commit("setInfo", ["menuIndex", 3]);
      this.$store.commit("setInfo", ["aboutIndex", "question1"]);
    },
    //获取验证码
    getMsg() {
      if (this.ruleForm.phone.length !== 11) {
        this.$message({
          message: "请输入正确的手机号",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      const that = this;
      axios
        .post("/api/front/sendCode?phone=" + that.ruleForm.phone)
        .then((res) => {
          if (res.data.code === 200) {
            that.$message({
              message: "发送成功",
              type: "success",
              center: true,
              offset: 300,
              duration: 2000,
            });
            if (!this.timer) {
              this.secendNo = 60;
              this.disableds = true;
              this.timer = setInterval(() => {
                if (this.secendNo > 0 && this.secendNo <= 60) {
                  this.secendNo--;
                } else {
                  this.disableds = false;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message.warning(res.data.msg);
          }
        });
    },
    /*
     *@date: 2019-11-07 15:49:09
     *@description:注册
     */
    register() {
      if (this.ruleForm.phone === "") {
        this.$message({
          message: "请输入手机号",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      if (this.ruleForm.code === "") {
        this.$message({
          message: "请输入验证码",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      if (this.ruleForm.password === "") {
        this.$message({
          message: "请输入密码",
          type: "warning",
          center: true,
          offset: 300,
          duration: 2000,
        });
        return false;
      }
      const that = this;
      if (that.ruleForm.isOk) {
        if (undefined == that.ruleForm.inPhone) {
          that.ruleForm.inPhone = "";
        }
        const data = {
          account: that.ruleForm.phone,
          captcha: that.ruleForm.code,
          password: that.ruleForm.password,
          spread: that.ruleForm.inPhone,
        };
        axios.post("/api/front/v2/register/mobile", data).then((res) => {
          if (res.data.code === 200) {
            that.$message({
              message: "注册成功",
              type: "success",
              center: true,
              offset: 300,
              duration: 2000,
            });
            this.token = res.data.token;
            window.localStorage.setItem("access_token", res.data.data.token);
            this.$store.commit("SET_INFO", ["token", res.data.data.token]);
            this.getUserInfo();
            that.$router.push("/index");
          } else {
            that.$message({
              message: res.data.message,
              type: "warning",
              center: true,
              offset: 300,
              duration: 1000,
            });
          }
        });
      } else {
        that.$message({
          message: "请先阅读服务协议，并勾选同意",
          type: "warning",
          center: true,
          offset: 300,
          duration: 1000,
        });
      }
    },
    //获取用户信息
    getUserInfo() {
      aoiGetUserinfo().then((res) => {
        if (res.code == 200) {
          console.log(res);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$store.commit("SET_INFO", ["userInfo", res.data]);
        }
      });
    },
    /*
     *@date: 2019-11-10 17:25:48
     *@description: 前往登录
     */
    tologin() {
      this.$router.push("/login");
    },
    //获取未读信息
    getMsgs() {
      const data = { type: 1, page: 1, limit: 10000 };
      axios.post("/wealth/getMsg", data).then((res) => {
        this.$store.commit("setInfo", ["totalSize", res.data.data.totalSize]);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  border-top: 1px dashed #999;
}
.iconBox {
  height: 38px;
  line-height: 38px;
  display: flex;
  align-items: center;
  img {
    margin-left: 20px;
    width: 22px;
    height: 22px;
    display: inline-block;
  }
}
.noMargrin {
  margin-bottom: 0;
}
.pwdtips {
  margin: 5px 0 10px;
  text-align: left;
  text-indent: 46px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  color: orange;
}
.pointer {
  cursor: pointer;
}
.registerBox {
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 70px;
  margin-bottom: 85px;
  .title {
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    margin: 17px 0;
  }
  .demo-ruleForm {
    padding-right: 74px;
  }
  .btnBox {
    border: 1px solid #dcdfe6;
    line-height: 38px;
  }
}
</style>